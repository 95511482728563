import {
	Box,
	Button,
	Flex,
	Grid,
	GridItem,
	Heading,
	Stack,
	Text,
} from "@chakra-ui/react";
import type { GroupData, QueueData } from "@flowby/firebase";
import { motion } from "framer-motion";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import QueueStatusBadge from "../shared/QueueStatusBadge";
import QueuersCount from "./QueuersCount";

export default function QueueList({
	store,
	queuesData,
	groupsData,
}: {
	store: string;
	queuesData: QueueData[];
	groupsData: GroupData[];
}) {
	const navigate = useNavigate();
	const queuesInAGroup = groupsData?.reduce<string[]>((acc, curr) => {
		return acc.concat(curr.queues);
	}, []);
	return (
		// pb 20 here to give some space when scrolling to bottom of list
		<Stack spacing={8} pb={20} data-testid="queue-list">
			<Stack>
				{queuesData.map((q) => {
					const isUnordered = q.config?.queueIsUnordered;
					return (
						!queuesInAGroup.includes(q.shortName) && (
							<Box
								as={RouterLink}
								to={
									isUnordered ? `q/${q.shortName}/queuers` : `q/${q.shortName}`
								}
								key={q.shortName}
							>
								<Grid
									templateColumns="repeat(6, 1fr)"
									as={motion.div}
									animate={{ rotate: [0, 0.5, -0.5, 0.5, -0.5, 0] }}
									transition={{ duration: "0.1" }}
									whileHover={{ scale: 1.02 }}
									p={5}
									shadow="md"
									borderWidth="1px"
									bg="white"
									borderRadius="0.375rem"
									_hover={{ textDecoration: "none" }}
								>
									<GridItem colSpan={5}>
										<Flex flexDir="column" justify="center">
											<Heading size="sm">
												{q.displayName}
												<QueueStatusBadge ml={1} status={q.state.status} />
											</Heading>
											<Text color="gray.600">{q.shortName}</Text>
										</Flex>
									</GridItem>
									{q.state.status !== "closed" && (
										<GridItem
											colSpan={1}
											alignSelf="center"
											justifySelf="center"
										>
											<QueuersCount count={q.state.count} />
										</GridItem>
									)}
								</Grid>
							</Box>
						)
					);
				})}
			</Stack>

			{groupsData.map((g) => (
				<Flex
					flexDir="column"
					key={g.shortName}
					padding={2}
					borderWidth={4}
					borderRadius={"0.375rem"}
					borderColor={"teal.500"}
				>
					<Flex>
						<Heading bg="white" mt={-6} px={2} size="md">
							{g.displayName}
						</Heading>
					</Flex>
					<Stack m={3}>
						{queuesData &&
							g.queues.map((queueName) => {
								const q = queuesData.find((queue) => {
									return queue.shortName === queueName;
								});
								const isUnordered = q?.config?.queueIsUnordered;
								return (
									q && (
										<Box
											as={RouterLink}
											to={
												isUnordered
													? `q/${q.shortName}/queuers`
													: `q/${q.shortName}`
											}
											key={q.shortName}
										>
											<Grid
												templateColumns="repeat(6, 1fr)"
												as={motion.div}
												animate={{ rotate: [0, 0.5, -0.5, 0.5, 0] }}
												transition={{ duration: "0.1" }}
												whileHover={{ scale: 1.02 }}
												p={5}
												shadow="md"
												borderWidth="1px"
												bg="white"
												borderRadius="0.375rem"
												_hover={{ textDecoration: "none" }}
											>
												<GridItem colSpan={5} alignSelf="center">
													<Heading size="sm">
														{q.displayName}
														<QueueStatusBadge ml={1} status={q.state.status} />
													</Heading>
													<Text p={0} color="gray.700">
														{q.shortName}
													</Text>
												</GridItem>
												{q.state.status !== "closed" && (
													<GridItem
														colSpan={1}
														alignSelf="center"
														justifySelf="center"
													>
														<QueuersCount count={q.state.count} />
													</GridItem>
												)}
											</Grid>
										</Box>
									)
								);
							})}
						{g.queues.length === 0 && (
							<Stack align="center" spacing={4}>
								<Text textAlign="center">
									This group is empty. You can add queues to the group in the
									admin panel.
								</Text>
								<Box>
									<Button size="sm" onClick={() => navigate(`/${store}/admin`)}>
										Open store admin
									</Button>
								</Box>
							</Stack>
						)}
					</Stack>
				</Flex>
			))}
		</Stack>
	);
}
